








































import AbstractAnimateDropdown from '@/components/abstractDropdowns/AbstractAnimateDropdown/Dropdown.vue';
import PlaceLogo from '@/components/ui/place/PlaceLogo/index.vue';
import PlaceItem from '@/components/ui/place/PlaceSelectDropdown/PlaceItem.vue';
import baseDropdown from '@/mixins/baseDropdown';
import Place from '@/models/place';
import { Component } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { State, Mutation } from 'vuex-class';

@Component({
  name: 'Dropdown',
  components: {
    PlaceItem,
    PlaceLogo,
    AbstractAnimateDropdown,
  },
})
export default class Dropdown extends baseDropdown {
  private contentAttrs = {
    class: 'wg-header-places-list',
  };

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('places', {
    namespace: 'place',
  })
  private places!: Place;

  @State('isDesktop', {
    namespace: 'user',
  })
  isDesktop!: boolean;

  @Mutation('selectPlace', {
    namespace: 'place',
  })
  private selectPlace!: MutationMethod;
}
