



























import NecessaryProductsPanelProduct
  from '@/components/ui/popups/promotions/PromotionViewPopup/components/NecessaryProductsPanelProduct.vue';
import BuyXGetY from '@/models/buyXGetY';
import { directive as onClickaway } from 'vue-clickaway';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'NecessaryProductsPanel',
  components: {
    NecessaryProductsPanelProduct,
  },
  directives: {
    onClickaway: onClickaway,
  },
})
export default class NecessaryProductsPanel extends Vue {
  @Prop({
    type: BuyXGetY,
    required: true,
  })
  private promotion!: BuyXGetY;

  private mounted() {
    document.body.appendChild(<HTMLElement> this.$el);
  }

  private beforeDestroy() {
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }

  private close() {
    this.$emit('close');
  }
}
