import PlaceDate from '@/utils/placeDate';
import { ClientChatMessageProps } from '@/interfaces/models/clientChatMessage';

export default class ClientChatMessage {
  public createdAt: number = 0;
  public editedAt: null | number = null;
  public id: string = '';
  // public image: Image = new Image();
  public staff: any | null = null;
  public statusClient: number = 0;
  public statusStaff: number = 0;
  public text: string = '';

  public constructor(clientChatMessageProps: ClientChatMessageProps) {
    this.createdAt = clientChatMessageProps.createdAt;
    this.editedAt = clientChatMessageProps.editedAt;
    this.id = clientChatMessageProps.id;

    // if (clientChatMessageProps.image) {
    //   this.image = new Image(clientChatMessageProps.image);
    // }

    this.staff = clientChatMessageProps.staff;
    this.statusClient = clientChatMessageProps.statusClient;
    this.statusStaff = clientChatMessageProps.statusStaff;
    this.text = clientChatMessageProps.text;
  }

  get date(): PlaceDate {
    return new PlaceDate(this.createdAt);
  }

  public isSameOwners(message: ClientChatMessage): boolean {
    return this.staff === message.staff
      || (
        this.staff !== null
        && message.staff !== null
        && this.staff.id === message.staff.id
      );
  }
}
