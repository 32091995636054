import hiddenCreditCardFactory from '@/factories/hiddenCreditCard.factory';
import HiddenCreditCard from '@/models/creditCard/hiddenCreditCard';
import { GetterTree, MutationTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import userService from '@/api/services/user.service';

interface UserState {
  user: null | any;
  creditCards: HiddenCreditCard[];
  isDesktop: boolean;
}

const namespaced: boolean = true;

const getDefaultState = (): UserState => ({
  user: null,
  creditCards: [],
  isDesktop: document.documentElement.clientWidth > 600,
});

const state: UserState = getDefaultState();

const getters: GetterTree<UserState, RootState> = {};

const actions = {
  async login({ commit }: ActionContext<UserState, RootState>, credentials: any) {
    return userService.login(credentials)
      .then(({ client, orders, creditCards }: any) => {
        const cards = creditCards.map(hiddenCreditCardFactory.createFromRawCreditCard);

        commit('setUser', client);
        commit('setCreditCards', cards);
        commit('orderHistory/setOrders', orders, { root: true });
        commit('orderHistory/setIsFetchingOrderHistory', false, { root: true });

        return client;
      });
  },
  async register({ commit }: ActionContext<UserState, RootState>, { orderId, credentials }: any) {
    return userService.register(orderId, credentials)
      .then(({ client, orders, creditCards }: any) => {
        const cards = creditCards.map(hiddenCreditCardFactory.createFromRawCreditCard);

        commit('setUser', client);
        commit('setCreditCards', cards);
        commit('orderHistory/setOrders', orders, { root: true });
        commit('orderHistory/setIsFetchingOrderHistory', false, { root: true });

        return client;
      });
  },
  async logout({ dispatch }: ActionContext<UserState, RootState>) {
    await dispatch('forceResetStore', null, { root: true });
  },
  async fetchUser({ commit, state }: ActionContext<UserState, RootState>) {
    const projectId = window.localStorage.getItem('projectId');

    if (null === state.user && window.localStorage.getItem(`${projectId}.accessToken`)) {
      try {
        const { client, orders, creditCards, failedPaymentOrder } = await userService.tryToAuthUserByCookie();
        const cards = creditCards.map(hiddenCreditCardFactory.createFromRawCreditCard);

        commit('setUser', client);
        commit('setCreditCards', cards);
        commit('orderHistory/setOrders', orders, { root: true });
        commit('orderHistory/setIsFetchingOrderHistory', false, { root: true });

        return failedPaymentOrder;
      } catch (e) {
      }
    }

    return null;
  },
  async removeCreditCard({ commit }: ActionContext<UserState, RootState>, creditCardId: string) {
    userService.removeCreditCard(creditCardId);

    commit('removeCreditCard', creditCardId);
  },
};

const mutations: MutationTree<UserState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIsDesktop(state, isDesktop: boolean) {
    state.isDesktop = isDesktop;
  },
  setUser(state, user: any) {
    state.user = user;
  },
  setCreditCards(state, creditCards: HiddenCreditCard[]) {
    state.creditCards = creditCards;
  },
  addCreditCard(state, creditCard: HiddenCreditCard) {
    const existingCreditCard = state.creditCards.find(({ id }: HiddenCreditCard): boolean => id === creditCard.id);

    if (existingCreditCard === undefined) {
      state.creditCards.unshift(creditCard);
    }
  },
  removeCreditCard(state, creditCardId: string) {
    const newCardIndex = state.creditCards.findIndex((creditCard: HiddenCreditCard): boolean => creditCard.id === creditCardId)

    if (newCardIndex !== -1) {
      state.creditCards.splice(newCardIndex, 1);
    }
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
