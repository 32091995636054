































import PlaceLogo from '@/components/ui/place/PlaceLogo/index.vue';
import stringFormatter from '@/utils/format/string';
import SystemDate from '@/utils/systemDate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Place from '@/models/place';
import isEqual from 'lodash.isequal';
import { Mutation, State } from 'vuex-class';
import { MutationMethod } from 'vuex';

const WEEKEND_SCHEDULE: [ number, number ] = [ 0, 0 ];
const ALL_DAY_WORK_SCHEDULE: [ number, number ] = [ 0, 86400 ];

@Component({
  name: 'PlaceListItem',
  components: {
    PlaceLogo
  },
})
export default class PlaceListItem extends Vue {
  @Prop({
    type: Place,
    required: true
  })
  private place!: Place;

  private get time() {
    const dayOfWeek = this.today.getEuropeanDay();
    const timetable = this.place.getTimetableByDayOfWeek(dayOfWeek).workingHours;

    if (isEqual(timetable, WEEKEND_SCHEDULE)) {
      return 'Выходной';
    } else if (isEqual(timetable, ALL_DAY_WORK_SCHEDULE)) {
      return 'Круглосуточно';
    }

    const formattedOpenTime = this.getHoursAndMinutesFromDayTimestamp(timetable[0]);
    const formattedCloseTime = this.getHoursAndMinutesFromDayTimestamp(timetable[1]);

    return `${formattedOpenTime} - ${formattedCloseTime}`;
  }

  private getHoursAndMinutesFromDayTimestamp(timestamp: number): string {
    const hours = Math.floor(timestamp / 3600);
    const minutes = timestamp % 3600 / 60;

    return `${stringFormatter.t2(hours)}:${stringFormatter.t2(minutes)}`;
  }

  @State('projectId')
  projectId!: string;

  @State('today', {
    namespace: 'timer',
  })
  private today!: SystemDate;

  private selectPlace() {
    this.$emit('select', this.place);

    this.selectPlaceMutation({
      place: this.place,
      projectId: this.projectId,
    });

    this.$router.push({
      name: 'takeAwayMenu',
      params: {
        projectId: this.$route.params.projectId,
        placeId: this.place.id
      }
    });
  }

  @Mutation('selectPlace', {
    namespace: 'place'
  })
  private selectPlaceMutation!: MutationMethod;
}
