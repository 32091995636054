import { VKUser } from '@/interfaces/vk';

export default class Client {
  public name: string = '';
  public phone: string = '';
  public photo: string | null = null;
  public uid: string | null = null;

  public static createByVkUser(vkUser: VKUser): Client {
    const client = new Client();

    client.name = `${vkUser.first_name} ${vkUser.last_name}`;
    client.photo = vkUser.photo_200;
    client.uid = vkUser.uid;

    return client;
  }
}
