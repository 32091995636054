


























































import Place from '@/models/place';
import PlaceListItem from '@/pages/SelectPlacePage/components/PlaceListItem.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {State} from 'vuex-class';
import BuyXGetY from '@/models/buyXGetY';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import getPopupController from '@/lib/vuePopup';
import PromotionViewPopup from '@/components/ui/popups/promotions/PromotionViewPopup/index.vue';

@Component({
  name: 'PlaceList',
  components: {
    PlaceListItem
  }
})
export default class PlaceList extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  places!: Place[];

  private popupController: PopupControllerPublicProperties = getPopupController();

  @State('promotions')
  promotions!: BuyXGetY[];

  private openPromotionViewPopup(promotion: BuyXGetY) {
    this.popupController.createPopup(PromotionViewPopup, {
      propsData: {
        promotion,
        isWithClient: false,
      },
    })
  }
}
