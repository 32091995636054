





































import ChooseProductPanelProduct
  from '@/components/ui/popups/promotions/PromotionViewPopup/components/ChooseProductPanelProduct.vue';
import PromotionProductAddPopup from '@/components/ui/popups/takeAway/PromotionProductAddPopup/index.vue';
import PromotionProductWithIngredientsAddPopup
  from '@/components/ui/popups/takeAway/PromotionProductWithIngredientsAddPopup/index.vue';
import getPopupController from '@/lib/vuePopup';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import BuyXGetY from '@/models/buyXGetY';
import Place from '@/models/place';
import Product from '@/models/product';
import TakeAway from '@/models/takeAway';
import { directive as onClickaway } from 'vue-clickaway';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';

@Component({
  name: 'ChooseProductPanel',
  components: {
    ChooseProductPanelProduct,
  },
  directives: {
    onClickaway: onClickaway,
  },
})
export default class ChooseProductPanel extends Vue {
  @Prop({
    type: BuyXGetY,
    required: true,
  })
  private promotion!: BuyXGetY;

  private popupController: PopupControllerPublicProperties = getPopupController();
  private selectedProductId: string | null = this.promotion.receivedProducts[0] ? this.promotion.receivedProducts[0].id : null;

  private get productsCountToReceive(): number {
    const productsCountToReceive = this.selectedPlace.settings.widget.maxProductCount - this.order.productsCount;

    return Math.min(productsCountToReceive, this.countOfReceivedProducts);
  }

  private get countOfReceivedProducts() {
    const addedCount = this.order.buyXGetY[this.promotion.id] ? this.order.buyXGetY[this.promotion.id].count : 0;

    return this.promotion.clientProgressReceivedCount - addedCount;
  }

  @Getter('getProductByProjectProductId', {
    namespace: 'takeAway/menu',
  })
  private getProductByProjectProductId!: (projectProductId: string) => Product | null;

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  private mounted() {
    document.body.appendChild(<HTMLElement> this.$el);
  }

  private beforeDestroy() {
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }

  private selectProduct() {
    if (!this.selectedProductId) {
      return;
    }

    const product = this.getProductByProjectProductId(this.selectedProductId);

    if (product === null) {
      return;
    }

    this.close();

    if (product.isHaveIngredientsForSelect) {
      this.openAddProductWithIngredientsPopup(product);

      return;
    }

    this.openAddProductPopup(product);
  }

  private openAddProductWithIngredientsPopup(product: Product) {
    this.popupController.createPopup(PromotionProductWithIngredientsAddPopup, {
      propsData: {
        product,
        promotion: this.promotion,
        maxCount: this.productsCountToReceive,
      },
      listeners: [
        {
          event: 'close',
          callback: this.$listeners.close,
        }
      ],
    });
  }

  private openAddProductPopup(product: Product) {
    this.popupController.createPopup(PromotionProductAddPopup, {
      propsData: {
        product,
        promotion: this.promotion,
        maxCount: this.productsCountToReceive,
      },
      listeners: [
        {
          event: 'close',
          callback: this.$listeners.close,
        }
      ],
    });
  }

  private close() {
    this.$emit('close');
  }
}
