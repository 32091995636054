































































































































































































































































import ChooseProductPanel from '@/components/ui/popups/promotions/PromotionViewPopup/components/ChooseProductPanel.vue';
import ChooseProductPanelProduct
  from '@/components/ui/popups/promotions/PromotionViewPopup/components/ChooseProductPanelProduct.vue';
import NecessaryProductsPanel
  from '@/components/ui/popups/promotions/PromotionViewPopup/components/NecessaryProductsPanel.vue';
import ReceivedProductsPanel
  from '@/components/ui/popups/promotions/PromotionViewPopup/components/ReceivedProductsPanel.vue';
import Place from '@/models/place';
import {Component, Prop, Vue} from 'vue-property-decorator';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import BuyXGetY from '@/models/buyXGetY';
import {Getter, State} from 'vuex-class';
import TakeAway from '@/models/takeAway';
import PromotionProductAddPopup from '@/components/ui/popups/takeAway/PromotionProductAddPopup/index.vue';
import PromotionProductWithIngredientsAddPopup from '@/components/ui/popups/takeAway/PromotionProductWithIngredientsAddPopup/index.vue';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import getPopupController from '@/lib/vuePopup';
import Product from '@/models/product';

@Component({
  name: 'PromotionViewPopup',
  components: {
    ChooseProductPanelProduct,
    ReceivedProductsPanel,
    NecessaryProductsPanel,
    ChooseProductPanel,
    PopupWrapper,
  },
})
export default class PromotionViewPopup extends Vue {
  @Prop({
    type: BuyXGetY,
    required: true,
  })
  promotion!: BuyXGetY;

  private popupController: PopupControllerPublicProperties = getPopupController();
  private isNecessaryProductsPanelDisplayed: boolean = false;
  private isReceivedProductsPanelDisplayed: boolean = false;
  private isChooseProductPanelDisplayed: boolean = false;

  private get isWithClient(): boolean {
    return Boolean(this.user);
  }

  private get productsCountToReceive(): number {
    const productsCountToReceive = this.selectedPlace.settings.widget.maxProductCount - this.order.productsCount;

    return Math.min(productsCountToReceive, this.countOfReceivedProducts);
  }

  @State('user', {
    namespace: 'user',
  })
  user!: any;

  @State('order', {
    namespace: 'takeAway/order',
  })
  order!: TakeAway;

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @Getter('getProductByProjectProductId', {
    namespace: 'takeAway/menu',
  })
  getProductByProjectProductId!: Function;

  private get countOfReceivedProducts() {
    const addedCount = this.order.buyXGetY[this.promotion.id] ? this.order.buyXGetY[this.promotion.id].count : 0;

    return this.promotion.clientProgressReceivedCount - addedCount;
  }

  private activate() {
    if (this.productsCountToReceive === 0) {
      return;
    }

    if (this.promotion.receivedProducts.length > 1) {
      this.isChooseProductPanelDisplayed = true;

      return;
    }

    const product = this.getProductByProjectProductId(this.promotion.receivedProducts[0].id);

    if (product.isHaveIngredientsForSelect) {
      this.openAddProductWithIngredientsPopup(product);
    } else {
      this.openAddProductPopup(product);
    }

    this.$emit('activate');
    this.close();
  }

  private openAddProductWithIngredientsPopup(product: Product) {
    this.popupController.createPopup(PromotionProductWithIngredientsAddPopup, {
      propsData: {
        product,
        promotion: this.promotion,
        maxCount: this.productsCountToReceive,
      },
    });
  }

  private openAddProductPopup(product: Product) {
    this.popupController.createPopup(PromotionProductAddPopup, {
      propsData: {
        product,
        promotion: this.promotion,
        maxCount: this.productsCountToReceive,
      },
    });
  }

  private openNecessaryProductsPanel() {
    this.isNecessaryProductsPanelDisplayed = true;
  }

  private closeNecessaryProductsPanel() {
    this.isNecessaryProductsPanelDisplayed = false;
  }

  private openReceivedProductsPanel() {
    this.isReceivedProductsPanelDisplayed = true;
  }

  private closeReceivedProductsPanel() {
    this.isReceivedProductsPanelDisplayed = false;
  }

  private closeChooseProductPanel() {
    this.isChooseProductPanelDisplayed = false;
  }

  private close() {
    this.$destroy();
  }
}
