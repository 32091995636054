import apiClient from '@/api/axiosClient';
import getClientApiRequestTransformer from '@/api/requestTransformers/clientApi.transformer';
import getSocketIdRequestTransformer from '@/api/requestTransformers/socketId.transformer';
import getChatTransformer from '@/api/responseTransformers/chat/chat.transformer';
import { AxiosResponse } from 'axios';

export default {
  async fetchChats(): Promise<any[]> {
    return apiClient.get('/client/chat/list', {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ],
    })
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async fetchChatByChatId(chatId: string): Promise<any[]> {
    return apiClient.get(`/client/chat/message/list/${chatId}`, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ],
      transformResponse: getChatTransformer
    })
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async fetchChatByOrderId(orderId: string): Promise<any[]> {
    return apiClient.get(`/client/chat/by-order/${orderId}`, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ],
      transformResponse: getChatTransformer
    })
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async createMessage(payload: any): Promise<any> {
    return apiClient.post('/client/chat/message/create', payload, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ]
    })
      .then(({ data }: AxiosResponse) => data);
  }
};
