





























































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'TheFooter'
})
export default class TheFooter extends Vue {
  @State('currentYear', {
    namespace: 'timer'
  })
  currentYear!: number;
}
