



















import TheFooter from '@/components/layout/TheFooter/index.vue';
import TheHeader from '@/components/layout/TheHeader/index.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'PageWrapper',
  components: {
    TheFooter,
    TheHeader
  }
})
export default class PageWrapper extends Vue {
}
