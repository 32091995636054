










































































































































import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import Ingredient from '@/models/ingredient';
import IngredientsGroup from '@/models/ingredientGroup';
import OrderProduct from '@/models/orderProduct';
import Place from '@/models/place';
import Product from '@/models/product';
import TakeAway from '@/models/takeAway';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Mutation, Getter } from 'vuex-class';
import OneOfGroup from './components/OneOfGroup.vue';
import OptionalGroup from './components/OptionalGroup.vue';

@Component({
  name: 'ProductWithIngredientsAddPopup',
  components: {
    DefaultImage,
    OneOfGroup,
    OptionalGroup,
    PopupWrapper,
  },
})
export default class ProductWithIngredientsAddPopup extends Vue {
  @Prop({
    type: Product,
    required: true,
  })
  private product!: Product;

  private orderProduct: OrderProduct = OrderProduct.createOrderProductWithZeroCount({
    product: this.product,
  });

  private get canIncrementProductCount(): boolean {
    return this.selectedPlace.settings.widget.maxProductCount > this.order.productsCount + this.orderProduct.count;
  }

  private get canIncrementIngredientCount(): boolean {
    return this.selectedPlace.settings.widget.maxIngredientCount > this.orderProduct.ingredientsCount;
  }

  private get isPlaceOpen(): boolean {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  @Getter('takeAwayOrDeliveryEnabled', {
    namespace: 'place',
  })
  private takeAwayOrDeliveryEnabled!: boolean;

  private incrementOrderProductIngredientCountByIngredient(ingredient: Ingredient) {
    if (this.canIncrementIngredientCount) {
      this.orderProduct.incrementOrderProductIngredientCountByIngredient(ingredient);
    }
  }

  private decrementOrderProductIngredientCountByIngredient(ingredient: Ingredient) {
    this.orderProduct.decrementOrderProductIngredientCountByIngredient(ingredient);
  }

  private selectOneOfIngredient(
    payload: { selectedIngredient: Ingredient, oldSelectedIngredient?: Ingredient, group: IngredientsGroup },
  ) {
    this.orderProduct.selectOneOfIngredient(payload);
  }

  private addOrderProductToOrder() {
    if (this.isPlaceOpen) {
      this.addOrderProductToOrderAction(this.orderProduct);
      this.close();
    }
  }

  private incrementOrderProductCount() {
    if (this.isPlaceOpen && this.canIncrementProductCount) {
      this.orderProduct.increment();
    }
  }

  private decrementOrderProductCount() {
    if (this.isPlaceOpen) {
      this.orderProduct.decrement();
    }
  }

  private close() {
    this.$emit('close');

    this.$destroy();
  }

  @Mutation('addOrderProductToOrder', {
    namespace: 'takeAway/order',
  })
  addOrderProductToOrderAction!: (orderProduct: OrderProduct) => void;
}
