








import OrderFailedConfirmedPopup from '@/components/ui/popups/takeAway/OrderFailedConfirmedPopup/index.vue';
import getPopupController from '@/lib/vuePopup/index';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { loadYmap } from 'vue-yandex-maps';
import { ActionMethod, MutationMethod } from 'vuex';
import { Action, Mutation, State } from 'vuex-class';
import DisabledWidgetOnboarding from '@/components/ui/core/DisabledWidgetOnboarding/index.vue';
import Place from '@/models/place';
import PlaceCommentPopup from "@/components/ui/popups/place/PlaceCommentPopup/index.vue";

@Component({
  name: 'BaseProjectPage',
  components: {
    DisabledWidgetOnboarding,
  },
})
export default class BaseProjectPage extends Vue {
  private popupController: PopupControllerPublicProperties = getPopupController();

  @State('projectId')
  projectId!: string;

  @State('user', {
    namespace: 'user',
  })
  user!: any;

  @State('selectedPlace', {
    namespace: 'place',
  })
  selectedPlace!: Place | null;

  @State('isLoadingProjectProps')
  isLoadingProjectProps!: boolean;

  @State('isResolvingMiddleware')
  private isResolvingMiddleware!: boolean;

  @State('isSocketConnected')
  private isSocketConnected!: boolean;

  @Watch('selectedPlace')
  onSelectedPlaceChanged(place: Place) {
    if (this.$route.name === 'selectPlace' || this.$route.name === 'installPwa') {
      return;
    }

    if (place.settings.widget.additionalInformationEnabled) {
      this.popupController.createPopup(PlaceCommentPopup, {
        propsData: {
          comment: place.settings.widget.additionalInformation,
        },
      });
    }
  }

  @Watch('user')
  onUserChanged(user: any, oldUser: any) {
    if (this.isSocketConnected) {
      this.connectToNewSocketRoom(user, oldUser);
    }
  }

  @Watch('isSocketConnected', {
    immediate: true,
  })
  private onIsSocketConnectedChanged(isSocketConnected: boolean) {
    if (isSocketConnected) {
      this.connectToNewSocketRoom(this.user, null);
    }
  }

  async created() {
    loadYmap({
      apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
      lang: 'ru_RU',
      coordorder: 'latlong',
      enterprise: false,
      version: '2.1',
    });

    if (document.documentElement.clientWidth > 600) {
      document.body.classList.add('jrm-desktop');
    }

    this.fetchProjectPropsByProjectId(this.projectId);
    this.fetchUser()
      .then((failedPaymentOrder: null | string): void => {
        if (failedPaymentOrder) {
          this.popupController.createPopup(OrderFailedConfirmedPopup, {
            propsData: {
              order: failedPaymentOrder,
              place: this.selectedPlace,
            }
          })
        }
      });
    this.initWidgetByVkApi();
    this.saveBeforeInstallPromptEvent();

    window.addEventListener('resize', this.updateIsDesktop);
    window.addEventListener('orientationchange', this.updateIsDesktop);
  }

  private connectToNewSocketRoom(user: any | null, oldUser: any | null): void {
    if (null !== user && (null === oldUser || oldUser.id !== user.id)) {
      this.$socket.emit('join', user.id);
    }

    if (oldUser !== null && (null === user || oldUser.id !== user.id)) {
      this.$socket.emit('leave', oldUser.id);
    }
  }

  private updateIsDesktop() {
    const isDesktop: boolean = document.documentElement.clientWidth > 600;

    if (isDesktop) {
      document.body.classList.add('jrm-desktop');
    } else {
      document.body.classList.remove('jrm-desktop');
    }

    this.setIsDesktop(isDesktop);
  }

  private saveBeforeInstallPromptEvent() {
    window.addEventListener('beforeinstallprompt', (beforeInstallPromptEvent) => {
      beforeInstallPromptEvent.preventDefault();

      this.setBeforeInstallPromptEvent(beforeInstallPromptEvent);
    });
  }

  private async initWidgetByVkApi() {
    if (undefined !== window.VK) {
      return window.VK.init(this.resizeVKWindow, process.env.VUE_APP_VK_API_VERSION);
    }
  }

  private async resizeVKWindow() {
    this.subscribeToVKScrollTopEvent();

    window.VK.callMethod('scrollTop');
  }

  private subscribeToVKScrollTopEvent() {
    window.VK.addCallback('onScrollTop', (vkScrollPos: number, vkHeight: number) => {
      const newVkWidth = 1200;
      const newVkHeight = vkHeight - 136;

      window.VK.callMethod('resizeWindow', newVkWidth, newVkHeight);
    });
  }

  @Action('fetchProjectPropsByProjectId')
  fetchProjectPropsByProjectId!: ActionMethod;

  @Mutation('setBeforeInstallPromptEvent')
  setBeforeInstallPromptEvent!: MutationMethod;

  @Mutation('setIsDesktop', {
    namespace: 'user',
  })
  setIsDesktop!: MutationMethod;

  @Action('fetchUser', {
    namespace: 'user',
  })
  fetchUser!: ActionMethod;
}
