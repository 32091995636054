import { PlaceProps } from '@/interfaces/models/place';
import Place from '@/models/place';
import { ProjectPropsAxiosResponse, ProjectPropsResponse } from '@/interfaces/api/project';
import axiosHelper from '@/helpers/axiosHelper';
import {BuyXGetYProps} from '@/interfaces/models/promotion';
import BuyXGetY from '@/models/buyXGetY';

export default axiosHelper.mergeAxiosResponseTransformerWithDefaults(
  (response: ProjectPropsAxiosResponse): ProjectPropsResponse => {
    return {
      ...response,
      places: response.places.map((placeProps: PlaceProps) => new Place(placeProps)),
      promotions: response.promotions.map(BuyXGetY.createFromRequest),
    };
  }
);
