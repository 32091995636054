


































import BuyXGetYProduct from '@/models/buyXGetYProduct';
import Place from '@/models/place';
import { directive as onClickaway } from 'vue-clickaway';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'NecessaryProductsPanelProduct',
  directives: {
    onClickaway: onClickaway,
  },
})
export default class NecessaryProductsPanelProduct extends Vue {
  @Prop({
    type: BuyXGetYProduct,
    required: true,
  })
  private product!: BuyXGetYProduct;

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  private get image() {
    if (this.product.image) {
      return this.$generateAssetsUrl(this.product.image);
    }

    return '../../../../../../assets/images/product-placeholder.png';
  }
}
