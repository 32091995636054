import ClientChatMessage from './clientChatMessage';
import { ClientChatProps } from '@/interfaces/models/clientChat';
import { ClientChatMessageProps } from '@/interfaces/models/clientChatMessage';

export default class ClientChat {
  public client: any;
  public id: string;
  public messages: ClientChatMessage[] = [];
  public place: string;

  constructor(clientChatProps: ClientChatProps) {
    this.client = clientChatProps.client;
    this.id = clientChatProps.id;

    if (clientChatProps.messages) {
      this.messages = clientChatProps.messages.map((clientChatMessageProps: ClientChatMessageProps) => {
        return new ClientChatMessage(clientChatMessageProps);
      });
    }

    this.place = clientChatProps.place;
  }

  public static isSameOwners(firstOwner: any | null, secondOwner: any | null) {
    return firstOwner === secondOwner
      || (firstOwner !== null && secondOwner !== null && firstOwner.id === secondOwner.id);
  }

  get messagesGroups() {
    return this.messages.reduce(
      (messagesGroups: { [key: string]: ClientChatMessage[][] }, message: ClientChatMessage) => {
        const messageHash = message.date.getFullHash();
        const currentBlock = messagesGroups[messageHash];

        if (!currentBlock) {
          messagesGroups[messageHash] = [
            [ message ]
          ];
        } else {
          const lastChatBlock = currentBlock[currentBlock.length - 1];

          if (lastChatBlock[0].isSameOwners(message)) {
            lastChatBlock.push(message);
          } else {
            currentBlock.push([ message ]);
          }
        }

        return messagesGroups;
      },
      {}
    );
  }

  addMessage(message: ClientChatMessage) {
    this.messages.push(message);
  }
}
