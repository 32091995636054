































































































































































































































































































































































































































































































































import CreditCardType from '@/components/ui/creditCard/CreditCardType.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import CreditCard from '@/models/creditCard/creditCard';
import HolderName from '@/models/creditCard/holderName';
import CardNumber from '@/models/creditCard/number';
import Type from '@/models/creditCard/type';
import Cvc from '@/models/creditCard/cvc';
import ExpirationDate from '@/models/creditCard/expirationDate';
import CreditCardTypeRepository from '@/repository/creditCardTypeRepository';
import cvc from '@/validators/rules/creditCard/cvc';
import expirationDate from '@/validators/rules/creditCard/expirationDate';
import holderName from '@/validators/rules/creditCard/holderName';
import number from '@/validators/rules/creditCard/number';
import { v4 } from 'uuid';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

const repository = new CreditCardTypeRepository();

@Component({
  name: 'AddCardPopup',
  components: {
    CreditCardType,
    DefaultImage,
    PopupWrapper,
  },
  validations: {
    card: {
      number: {
        required,
        number,
      },
      expirationDate: {
        required,
        expirationDate,
      },
      cvc: {
        required,
        cvc,
      },
      holderName: {
        required,
        holderName,
      },
    },
  },
})
export default class ProductAddPopup extends Vue {
  private card: any = {
    number: '',
    expirationDate: '',
    cvc: '',
    holderName: '',
    shouldBeSave: true,
  };

  private get isValidCvc(): boolean {
    return Cvc.isValid(this.card.cvc, this.cardType);
  }

  private get cardType(): Type | undefined {
    return repository.findByNumber(this.card.number);
  }

  private get expirationMonth(): string {
    return this.card.expirationDate.split('/')[0];
  }

  private get expirationYear(): string {
    return this.card.expirationDate.split('/')[1];
  }

  @Watch('card.number', {
    immediate: true,
  })
  onCardNumberChanged(number: string) {
    const validation = <any> this.$v;
    const formattedNumber = CardNumber.format(number);

    if (formattedNumber !== this.card.number) {
      this.card.number = formattedNumber;
    }

    if (!validation.card.number.$invalid) {
      this.focusToFirstInputWithInvalidValue();
    }
  }

  @Watch('card.expirationDate', {
    immediate: true,
  })
  onCardExpirationDateChanged(expirationDate: string, previousExpirationDate: string) {
    const validation = <any> this.$v;
    const formattedExpirationDate = ExpirationDate.formatExpirationDate(expirationDate, previousExpirationDate);

    if (formattedExpirationDate !== this.card.expirationDate) {
      this.card.expirationDate = formattedExpirationDate;
    }

    if (!validation.card.expirationDate.$invalid) {
      this.focusToFirstInputWithInvalidValue();
    }
  }

  @Watch('card.cvc', {
    immediate: true,
  })
  onCardCvcChanged(cvc: string) {
    const validation = <any> this.$v;
    const formattedCvc = Cvc.format(cvc);

    if (formattedCvc !== this.card.cvc) {
      this.card.cvc = formattedCvc;
    }

    if (!validation.card.cvc.$invalid) {
      this.focusToFirstInputWithInvalidValue();
    }
  }

  private addCard() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      const begateway = new window.BeGatewayCSE('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2h/oumi5snDnnfRsPtZ7R5Lqu4JAPCacnFFOlu5Lbj9qXMg45VGQGhX1tK69W4aa+C2e7k/+wJii2z/Mtuf2K/iutzzwV4U6Q2vczy2ODxkgV5mely6oExL7czwIyEGKm3OOtoOb8RqIg6BFyyt1dzRw0QcT7mAIJE5YQBD/1MsXH2wqlJUavadg4RNdlV5Yyg5XnslyEx9Iib6hMGAKbR5wdSwUXfN5WgdHsXIxcG9Fnda+9K6/z92/U/en8+2h+xntiQEQwb3RJye4AOTkJJTUZeAaJhtLqnX3i4CAmNnnWdOsG3k5E1kux3XmVtyLgkOpI56f6Y9j4AUvukvz0wIDAQAB');
      begateway.encrypt("begateway-encrypted-form");

      const cvc = document.querySelector<any>('input[name="encrypted_verification_value"]').value;
      const year = document.querySelector<any>('input[name="encrypted_exp_year"]').value;
      const month = document.querySelector<any>('input[name="encrypted_exp_month"]').value;
      const holder = document.querySelector<any>('input[name="encrypted_holder"]').value;
      const number = document.querySelector<any>('input[name="encrypted_number"]').value;

      const card = new CreditCard(
        v4(),
        new CardNumber(this.card.number),
        ExpirationDate.createFromString(this.card.expirationDate),
        new Cvc(this.card.cvc),
        new HolderName(this.card.holderName),
        {
          cvc,
          year,
          month,
          holder,
          number,
        },
        this.card.shouldBeSave,
      );
      this.$emit('add', card);
      this.close();
    }
  }

  private focusToFirstInputWithInvalidValue(): void {
    const validation = <any> this.$v;
    const refs = <any> this.$refs;

    if (validation.card.number.$invalid) {
      refs.numberInput.focus();
    } else if (validation.card.expirationDate.$invalid) {
      refs.expirationDateInput.focus();
    } else if (validation.card.cvc.$invalid) {
      refs.cvcInput.focus();
    } else if (validation.card.holderName.$invalid) {
      refs.holderNameInput.focus();
    }
  }

  private close() {
    this.$destroy();
  }
}
