import store from '@/store';
import Vue from 'vue';
import Router from 'vue-router';
import BasePageSpinner from '@/components/base/BasePageSpinner.vue';
import SelectPlacePage from '@/pages/SelectPlacePage/index.vue';
import TakeAwayMenu from '@/pages/TakeAwayMenu/index.vue';
import middleware from '@/middleware';
import { Route } from 'vue-router/types/router';
import BaseProjectPage from '@/components/layout/BaseProjectPage/index.vue';

window.addEventListener('popstate', () => {
  store.commit('activateBackButton');
});

Vue.use(Router);

const router = new Router(<any>{
  mode: 'history',
  scrollBehavior(to: Route, from: Route, savedPosition: { x: number, y: number }) {
    if (savedPosition) {
      return savedPosition;
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  routes: [
    {
      path: '/',
      name: 'mainPage',
      component: () => ({
        component: import(/* webpackChunkName: "mainPage" */'@/pages/MainPage/index.vue'),
        loading: BasePageSpinner,
        delay: 0,
      }),
    },
    {
      path: '/:projectId',
      name: 'baseProjectPage',
      component: BaseProjectPage,
      children: [
        {
          path: '',
          name: 'selectPlace',
          component: SelectPlacePage,
          children: [
            {
              path: 'install',
              name: 'installPwa',
              component: () => ({
                component: import(/* webpackChunkName: "installPwa" */'@/pages/InstallPwa/index.vue'),
                loading: BasePageSpinner,
                delay: 0,
              }),
            },
          ],
        },
        {
          path: 'register/:orderId',
          name: 'registerByOrder',
          component: () => ({
            component: import(/* webpackChunkName: "registerByOrder" */'@/pages/RegisterByOrder/index.vue'),
            loading: BasePageSpinner,
            delay: 0,
          }),
        },
        {
          path: 'take-away/:placeId',
          name: 'takeAwayMenu',
          component: TakeAwayMenu,
        },
        {
          path: 'promotions/:placeId',
          name: 'promotions',
          component: () => ({
            component: import(/* webpackChunkName: "promotions" */'@/pages/Promotions/index.vue'),
            loading: BasePageSpinner,
            delay: 0,
          }),
        },
        {
          path: 'place/:placeId',
          name: 'placeView',
          component: () => ({
            component: import(/* webpackChunkName: "placeView" */'@/pages/PlaceView/index.vue'),
            loading: BasePageSpinner,
            delay: 0,
          }),
        },
        {
          path: 'order/history',
          name: 'orderHistory',
          component: () => ({
            component: import(/* webpackChunkName: "orderHistory" */'@/pages/OrderHistory/index.vue'),
            loading: BasePageSpinner,
            delay: 0,
          }),
          children: [
            {
              path: ':orderId',
              name: 'orderView',
              component: () => ({
                component: import(/* webpackChunkName: "orderView" */'@/pages/OrderView/index.vue'),
                loading: BasePageSpinner,
                delay: 0,
              }),
            },
          ],
        },
        // {
        //   path: 'chat',
        //   name: 'chatList',
        //   component: () => ({
        //     component: import(/* webpackChunkName: "chatList" */'@/pages/ChatList/index.vue'),
        //     loading: BasePageSpinner,
        //     delay: 0,
        //   }),
        // },
        // {
        //   path: 'chat/view/:chatId',
        //   name: 'chatView',
        //   component: () => ({
        //     component: import(/* webpackChunkName: "chatView" */'@/pages/ChatView/index.vue'),
        //     loading: BasePageSpinner,
        //     delay: 0,
        //   }),
        // },
        // {
        //   path: 'order/chat/view/:orderId',
        //   name: 'orderChatView',
        //   component: () => ({
        //     component: import(/* webpackChunkName: "orderChatView" */'@/pages/OrderChatView/index.vue'),
        //     loading: BasePageSpinner,
        //     delay: 0,
        //   }),
        // },
        {
          path: 'receipt/view/:orderId',
          name: 'orderViewFromReceipt',
          component: () => ({
            component: import(/* webpackChunkName: "orderViewFromReceipt" */'@/pages/OrderViewFromReceipt/index.vue'),
            loading: BasePageSpinner,
            delay: 0,
          }),
        },
      ]
    },
  ],
});

router.beforeEach(middleware);

export default router;
