








































import phone from '@/validators/rules/phone';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { AsYouType } from 'libphonenumber-js';
import { State } from 'vuex-class';
import {required} from 'vuelidate/lib/validators';

@Component({
  name: 'PhoneForm',
  validations: {
    phone: {
      required,
      phone,
    }
  }
})
export default class PhoneForm extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private currentPhone!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  private hasError!: boolean;

  private phone: string = this.currentPhone;

  @Watch('phone')
  private onPhoneChanged(phone: string) {
    const formattedPhone = new AsYouType().input(phone);

    if (formattedPhone !== phone) {
      this.phone = formattedPhone;
    }

    this.$emit('update:currentPhone', formattedPhone);
  }
}
