














































































































import PlaceAppLogo from '@/components/ui/place/PlaceAppLogo/index.vue';
import Place from '@/models/place';
import { Component, Vue } from 'vue-property-decorator';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import { State, Mutation } from 'vuex-class';
import { MutationMethod } from 'vuex';
import place from "@/store/place";

const IOS_DEVICES = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
];

@Component({
  name: 'InstallAppPopup',
  components: {
    PlaceAppLogo,
    PopupWrapper,
  },
})
export default class InstallAppPopup extends Vue {
  private isIos: boolean = IOS_DEVICES.includes(navigator.platform)
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  private get appLogo() {
    return `/img/icons/${this.selectedPlace.id}/512x512.png`;
  }

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('beforeInstallPromptEvent')
  private beforeInstallPromptEvent!: Event | any;

  @State('projectId')
  projectId!: string;

  private install() {
    if (this.beforeInstallPromptEvent) {
      this.beforeInstallPromptEvent.prompt();
      this.beforeInstallPromptEvent.userChoice
        .then((choiceResult: any) => {
          if ('accepted' === choiceResult.outcome) {
            this.installApp();
            this.close();
          }
        });
    }
  }

  private close() {
    this.$destroy();
  }

  @Mutation('installApp')
  private installApp!: MutationMethod;
}
