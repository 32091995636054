





















































import ProductUnit from './components/ProductUnit.vue';
import OrderClientAddPopup from '@/components/ui/popups/takeAway/OrderClientAddPopup/index.vue';
import Place from '@/models/place';
import TakeAway from '@/models/takeAway';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation, Getter } from 'vuex-class';
import { MutationPayload, MutationMethod } from 'vuex';
import { REMOVE_ORDER_MUTATION_TYPE } from '@/store/takeAway/order';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import PromotionProductUnit from '@/components/ui/popups/takeAway/CartPopup/components/PromotionProductUnit.vue';
import ConfirmationModal from "@/components/ui/popups/core/ConfirmationModal/index.vue";

@Component({
  name: 'CartPopup',
  components: {
    PromotionProductUnit,
    ProductUnit,
    PopupWrapper
  }
})
export default class CartPopup extends Vue {
  private popupController: PopupControllerPublicProperties = getPopupController();

  @State('order', {
    namespace: 'takeAway/order'
  })
  private readonly order!: TakeAway;

  private get isPlaceOpen(): boolean {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private readonly selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private readonly nowInSelectedPlaceInSeconds!: number;

  @Watch('order.orderProducts.length')
  private onOrderProductsLengthChange(length: number) {
    if (length === 0) {
      this.close();
    }
  }

  @Watch('isPlaceOpen')
  private onIsPlaceOpenChange(isPlaceOpen: number) {
    if (!isPlaceOpen) {
      this.removeOrder();

      this.close();
    }
  }

  private created() {
    const unsubscribe = this.$store.subscribe(({ type }: MutationPayload) => {
      if (REMOVE_ORDER_MUTATION_TYPE === type) {
        this.close();
      }
    });

    this.$on('hook:beforeDestroy', unsubscribe);
  }

  private removeAllOrderProducts() {
    this.popupController.createPopup(ConfirmationModal, {
      propsData: {
        question: 'Вы точно хотите очистить корзину?',
        confirmButtonName: 'Очистить корзину',
      },
      listeners: [
        {
          event: 'confirm',
          callback: async () => {
            this.removeAllOrderProductsMutation();
            this.close();
          },
        },
      ],
    });
  }

  private checkout() {
    this.popupController.createPopup(OrderClientAddPopup);
  }

  private close() {
    this.$destroy();
  }

  @Mutation('removeAllOrderProducts', {
    namespace: 'takeAway/order'
  })
  removeAllOrderProductsMutation!: () => void;

  @Mutation('removeOrder', {
    namespace: 'takeAway/order'
  })
  private removeOrder!: MutationMethod;
}
