






















import {Component, Prop, Vue} from 'vue-property-decorator';
import MenuCategory from '@/models/menuCategory';
import TakeAwayMenuCategoryProduct from './TakeAwayMenuCategoryProduct.vue';

@Component({
  name: 'TakeAwayMenuCategory',
  components: {
    TakeAwayMenuCategoryProduct
  }
})
export default class TakeAwayMenuCategory extends Vue {
  @Prop({
    type: MenuCategory,
    required: true,
  })
  private category!: MenuCategory;
}
