import apiKeyHelper from '@/helpers/apiKeyHelper';
import string from '@/utils/format/string';
import Vue from 'vue';
import Widget from './Widget.vue';
import i18n from './plugins/vue-i18n';
import router from './router';
import store from './store';
import './class-component-hooks';
import './components/base';
import './directives';
import './filters';
import './plugins';
import './registerServiceWorker';
import { mapMutations } from 'vuex';
import {CreateElement} from 'vue/types/vue';
import * as Sentry from '@sentry/vue';

export const WIDGET_CONTAINER_ID = 'jrm-embeded-content';

let projectId = apiKeyHelper.getApiKeyByWidgetContainerId(WIDGET_CONTAINER_ID);

if (process.env.NODE_ENV === 'development') {
  const url = new URL(window.location.href);
  const pathUnits = url.pathname.split('/');

  if (pathUnits.length > 1) {
    projectId = pathUnits[1];
  }
}

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  attachStacktrace: true,
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h: CreateElement) => h(Widget),
  created() {
    this.setApiKey(projectId);
  },
  methods: mapMutations([
    'setApiKey',
  ])
}).$mount(`#${WIDGET_CONTAINER_ID}`);
