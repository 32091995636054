


























































import OrderProductWithIngredientsEditPopup from '@/components/ui/popups/takeAway/OrderProductWithIngredientsEditPopup/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import Ingredient from '@/models/ingredient';
import OrderProduct from '@/models/orderProduct';
import OrderProductIngredient from '@/models/orderProductIngredient';
import Place from '@/models/place';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';

@Component({
  name: 'TakeAwayMenuCartPanelProduct',
  components: { DefaultImage },
})
export default class TakeAwayMenuCartPanelProduct extends Vue {
  @Prop({
    type: OrderProduct,
    required: true,
  })
  product!: OrderProduct;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private get isPlaceOpen(): boolean {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  private get firstThreeIngredients(): Ingredient[] {
    return this.flattenIngredients.slice(0, 3);
  }

  private get flattenIngredients(): Ingredient[] {
    return this.product.ingredients.reduce(
      (
        ingredients: Ingredient[],
        orderProductIngredient: OrderProductIngredient,
      ): Ingredient[] => {
        for (let count = 0; count < orderProductIngredient.count; count++) {
          ingredients.push(orderProductIngredient.ingredient);
        }

        return ingredients;
      },
      [],
    );
  }

  private get countOfIngredientsOverThree(): number {
    return this.flattenIngredients.length - 3;
  }

  private openOrderProductViewPopup() {
    if (this.isPlaceOpen) {
      this.popupController.createPopup(OrderProductWithIngredientsEditPopup, {
        propsData: {
          initOrderProduct: this.product,
        },
      });
    }
  }
}
