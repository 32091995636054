
































import PlaceAppLogo from '@/components/ui/place/PlaceAppLogo/index.vue';
import InstallAppPopup from '@/components/ui/popups/core/InstallAppPopup/index.vue';
import getPopupController from '@/lib/vuePopup/index';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces/index';
import Place from '@/models/place';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'InstallPwaNotice',
  components: {
    PlaceAppLogo
  },
})
export default class InstallPwaNotice extends Vue {
  private popupController: PopupControllerPublicProperties = getPopupController();

  @State('selectedPlace', {
    namespace: 'place',
  })
  selectedPlace!: Place;

  private install() {
    this.popupController.createPopup(InstallAppPopup);
  }
}
