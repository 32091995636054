

















import PlaceMapMarkerBalloon from './PlaceMapMarkerBalloon.vue';
import Place from '@/models/place';
import { ymapMarker } from 'vue-yandex-maps';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { Mutation, State } from 'vuex-class';

@Component({
  name: 'PlaceMapMarker',
  components: {
    ymapMarker,
    PlaceMapMarkerBalloon,
  },
})
export default class PlaceMapMarker extends Vue {
  @Prop({
    type: Place,
    required: true,
  })
  private place!: Place;

  private markerIcon: any = {
    layout: 'default#image',
    imageHref: `/img/icons/${this.place.id}/logo.png`,
    imageSize: [48, 48],
    // imageOffset: [-10, -50],
    contentLayout: '<div class="circle-layout"></div>'
  };

  @State('projectId')
  projectId!: string;

  private bindListener() {
    const button = document.getElementById('select-place-button');

    if (button instanceof HTMLElement) {
      button.addEventListener('click', this.selectPlace);
    }
  }

  private unbindListener() {
    const button = document.getElementById('select-place-button');

    if (button instanceof HTMLElement) {
      button.addEventListener('click', this.selectPlace);
    }
  }

  private selectPlace() {
    this.$emit('select', this.place);

    this.selectPlaceMutation({
      place: this.place,
      projectId: this.projectId,
    });

    this.$router.push({
      name: 'takeAwayMenu',
      params: {
        projectId: this.$route.params.projectId,
        placeId: this.place.id,
      },
    });
  }

  @Mutation('selectPlace', {
    namespace: 'place',
  })
  private selectPlaceMutation!: MutationMethod;
}
