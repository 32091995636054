




import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Widget',
})
export default class Widget extends Vue {
}
