
































import PlaceLogo from '@/components/ui/place/PlaceLogo/index.vue';
import Place from '@/models/place';
import stringFormatter from '@/utils/format/string';
import SystemDate from '@/utils/systemDate';
import isEqual from 'lodash.isequal';
import {Prop, Vue, Component} from 'vue-property-decorator';
import { State } from 'vuex-class';

const WEEKEND_SCHEDULE: [ number, number ] = [ 0, 0 ];
const ALL_DAY_WORK_SCHEDULE: [ number, number ] = [ 0, 86400 ];

@Component({
  name: 'PlaceItem',
  components: {
    PlaceLogo,
  },
})
export default class PlaceItem extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private place!: Place;

  private get time() {
    const dayOfWeek = this.today.getEuropeanDay();
    const timetable = this.place.getTimetableByDayOfWeek(dayOfWeek).workingHours;

    if (isEqual(timetable, WEEKEND_SCHEDULE)) {
      return 'Выходной';
    } else if (isEqual(timetable, ALL_DAY_WORK_SCHEDULE)) {
      return 'Круглосуточно';
    }

    const formattedOpenTime = this.getHoursAndMinutesFromDayTimestamp(timetable[0]);
    const formattedCloseTime = this.getHoursAndMinutesFromDayTimestamp(timetable[1]);

    return `${formattedOpenTime} - ${formattedCloseTime}`;
  }

  private getHoursAndMinutesFromDayTimestamp(timestamp: number): string {
    const hours = Math.floor(timestamp / 3600);
    const minutes = timestamp % 3600 / 60;

    return `${stringFormatter.t2(hours)}:${stringFormatter.t2(minutes)}`;
  }

  @State('today', {
    namespace: 'timer',
  })
  private today!: SystemDate;

  @State('projectId')
  private projectId!: string;

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;
}
