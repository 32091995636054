import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import modules from '@/store/index';
import projectApi from '@/api/services/project.service';
import { ProjectPropsResponse } from '@/interfaces/api/project';
import BuyXGetY from '@/models/buyXGetY';

Vue.use(Vuex);

export interface RootState {
  isLoadingProjectProps: boolean;
  projectId: string | null;
  promotions: BuyXGetY[];
  beforeInstallPromptEvent: Event | null;
  appInstalled: boolean;
  isResolvingMiddleware: boolean;
  isSocketConnected: boolean;
  backActivated: boolean;
}

export default new Vuex.Store<RootState>({
  state: {
    isLoadingProjectProps: true,
    projectId: null,
    promotions: [],
    beforeInstallPromptEvent: null,
    appInstalled: false,
    isResolvingMiddleware: false,
    isSocketConnected: false,
    backActivated: false,
  },
  actions: {
    fetchProjectPropsByProjectId({ commit, dispatch }: ActionContext<RootState, RootState>, projectId: string) {
      commit('setIsLoadingProjectProps', true);
      return projectApi.fetchProjectPropsByProjectId(projectId)
        .then((response: ProjectPropsResponse) => {
          dispatch('place/setPlaces', response.places);
          dispatch('timer/startTimer', response.now);
          commit('setPromotions', response.promotions);
          commit('setIsLoadingProjectProps', false);
        });
    },
    forceResetStore({ commit }: ActionContext<RootState, RootState>) {
      commit('orderHistory/resetState');
      commit('chat/resetState');
    },
  },
  mutations: {
    setIsLoadingProjectProps(state: any, isLoadingProjectProps: boolean) {
      state.isLoadingProjectProps = isLoadingProjectProps;
    },
    setApiKey(state, projectId: string) {
      window.localStorage.setItem('projectId', projectId);

      state.projectId = projectId;
    },
    socketConnect(state) {
      state.isSocketConnected = true;
    },
    socketDisconnect(state) {
      state.isSocketConnected = false;
    },
    setPromotions(state, promotions: BuyXGetY[]) {
      state.promotions = promotions;
    },
    setBeforeInstallPromptEvent(state, beforeInstallPromptEvent: Event) {
      state.beforeInstallPromptEvent = beforeInstallPromptEvent;
    },
    installApp(state) {
      state.appInstalled = true;
    },
    setIsResolvingMiddleware(state, isResolvingMiddleware: boolean) {
      state.isResolvingMiddleware = isResolvingMiddleware;
    },
    activateBackButton(state) {
      state.backActivated = true;
    },
    deactivateBackButton(state) {
      state.backActivated = false;
    },
  },
  modules,
  strict: process.env.NODE_ENV !== 'production',
});
