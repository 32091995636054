


































import BuyXGetYProduct from '@/models/buyXGetYProduct';
import { directive as onClickaway } from 'vue-clickaway';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ChooseProductPanelProduct',
  directives: {
    onClickaway: onClickaway,
  },
})
export default class ChooseProductPanelProduct extends Vue {
  @Prop({
    type: BuyXGetYProduct,
    required: true,
  })
  private product!: BuyXGetYProduct;

  @Prop({
    type: String,
    default: null,
  })
  private selectedProductId!: string | null;

  private get image() {
    if (this.product.image) {
      return this.$generateAssetsUrl(this.product.image);
    }

    return '../../../../../../assets/images/product-placeholder.png';
  }
}
