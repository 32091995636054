















































































































import PageWrapper from '@/components/layout/PageWrapper/index.vue';
import TheHeader from '@/components/layout/TheHeader/index.vue';
import ThePlaceNavbar from '@/components/layout/ThePlaceNavbar/index.vue';
import TheFooterWithInstallAppAlert from '@/components/layout/TheFooterWithInstallAppAlert/index.vue';
import TheFooter from '@/components/layout/TheFooter/index.vue';
import PlaceSelectDropdown from '@/components/ui/place/PlaceSelectDropdown/Dropdown.vue';
import { FacebookGetLoginStatusResponse, FacebookLoginStatuses } from '@/interfaces/facebook';
import { VKUser } from '@/interfaces/vk';
import Client from '@/models/client';
import MenuCategory from '@/models/menuCategory';
import OrderProduct from '@/models/orderProduct';
import Place from '@/models/place';
import Product from '@/models/product';
import TakeAway from '@/models/takeAway';
import InstallPwaNotice from '@/pages/TakeAwayMenu/components/InstallPwaNotice.vue';
import PlaceAlmostClosedNotice from '@/pages/TakeAwayMenu/components/PlaceAlmostClosedNotice.vue';
import PlaceAlreadyClosedNotice from '@/pages/TakeAwayMenu/components/PlaceAlreadyClosedNotice.vue';
import takeAway from '@/store/takeAway';
import { ONE_MINUTE_IN_SECONDS, ONE_SECOND_IN_MILLISECONDS } from '@/utils/format/date';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ActionMethod, MutationMethod, MutationPayload } from 'vuex';
import { Action, State, Mutation, Getter } from 'vuex-class';
import TakeAwayMenuCartPanel from './components/TakeAwayMenuCartPanel.vue';
import TakeAwayMenuCategory from './components/TakeAwayMenuCategory.vue';
import TakeAwayMenuCategoryFilter from '@/pages/TakeAwayMenu/components/TakeAwayMenuCategoryFilter.vue';
import BuyXGetY from '@/models/buyXGetY';
import PromotionCard from '@/pages/TakeAwayMenu/components/PromotionCard.vue';
import {REMOVE_ORDER_MUTATION_TYPE} from "@/store/takeAway/order";

const IOS_DEVICES = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
];

@Component({
  name: 'TakeAwayMenu',
  computed: {
    takeAway() {
      return takeAway
    }
  },
  components: {
    PlaceAlmostClosedNotice,
    PlaceAlreadyClosedNotice,
    InstallPwaNotice,
    TakeAwayMenuCategoryFilter,
    PromotionCard,
    PageWrapper,
    ThePlaceNavbar,
    TheHeader,
    TakeAwayMenuCartPanel,
    TakeAwayMenuCategory,
    PlaceSelectDropdown,
    TheFooterWithInstallAppAlert,
    TheFooter
  },
})
export default class TakeAwayMenu extends Vue {
  private isIos: boolean = IOS_DEVICES.includes(navigator.platform)
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  private installPwaNoticeClosed: boolean = false;
  private installationPossible: boolean = false;
  private placeAlreadyClosedNoticeClosed: boolean = true;
  private placeAlmostClosedNoticeClosed: boolean = true;

  private get canInstallApp(): boolean {
    return !this.appInstalled && this.installationPossible;
  }

  private get isPlaceOpen(): boolean {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  @State('menu', {
    namespace: 'takeAway/menu',
  })
  private menu!: MenuCategory[];

  @State('promotions', {
    namespace: 'promotion',
  })
  private promotions!: BuyXGetY[];

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  @Getter('takeAwayOrDeliveryEnabled', {
    namespace: 'place',
  })
  private takeAwayOrDeliveryEnabled!: boolean;

  @Getter('getProductByRestoredProduct', {
    namespace: 'takeAway/menu',
  })
  private getProductByRestoredProduct!: (restoredProduct: any) => Product | null;

  @State('places', {
    namespace: 'place',
  })
  private places!: Place;

  @State('isFetchingMenu', {
    namespace: 'takeAway/menu',
  })
  private isFetchingMenu!: boolean;

  @State('isFetchingPromotions', {
    namespace: 'promotion',
  })
  private isFetchingPromotions!: boolean;

  @State('beforeInstallPromptEvent')
  beforeInstallPromptEvent!: Event | any;

  @State('appInstalled')
  private appInstalled!: boolean;

  @State('projectId')
  private projectId!: boolean;

  @Watch('selectedPlace', {
    immediate: true,
  })
  private onSelectedPlaceChanged(place: Place) {
    if (place.id !== this.$route.params.placeId) {
      this.$router.replace({
        params: {
          placeId: place.id,
        },
      });
    }

    this.fetchMenuByPlaceId(this.selectedPlace.id);
    this.fetchPromotions(this.selectedPlace.id);
  }

  @Watch('beforeInstallPromptEvent', {
    immediate: true,
  })
  private onBeforeInstallPromptEventChanged(beforeInstallPromptEvent: any | null) {
    this.installPwaNoticeClosed = null === beforeInstallPromptEvent;
    this.installationPossible = beforeInstallPromptEvent !== null || this.isIos;
  }

  @Watch('isPlaceOpen')
  private onIsPlaceOpenChange(isPlaceOpen: number) {
    if (isPlaceOpen) {
      this.closePlaceAlreadyClosedNotice();

      this.$nextTick(() => this.openPlaceAlmostClosedNotice());
    } else {
      this.closePlaceAlmostClosedNotice();

      this.$nextTick(() => this.openPlaceAlreadyClosedNotice());
    }
  }

  @Watch('placeAlmostClosedNoticeClosed')
  private onPlaceAlmostClosedNoticeClosedChange(placeAlmostClosedNoticeClosed: number) {
    if (placeAlmostClosedNoticeClosed && this.isPlaceOpen) {
      const timeToClose = this.selectedPlace.getTimeToClose(this.nowInSelectedPlaceInSeconds);
      let timeToReopen = ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS * 5;

      if (timeToClose < ONE_MINUTE_IN_SECONDS * 10) {
        timeToReopen = ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS * 2;
      }

      setTimeout(
        () => {
          this.placeAlmostClosedNoticeClosed = false;
        },
        timeToReopen,
      );
    }
  }

  @Watch('isFetchingMenu')
  onIsFetchingMenu(isFetchingMenu: boolean, oldIsFetchingMenu: boolean) {
    if (oldIsFetchingMenu && !isFetchingMenu) {
      this.setOrder(this.restoreOrder());
    }
  }

  private created() {
    this.initWidgetByCurrentEnvironment();

    this.selectPlaceByPlaceIdFromRouter();

    this.closeInstallPwaNoticeIfItIsAlreadyInstalled();

    this.openPlaceAlreadyClosedNoticeIfItNeeded();

    this.openPlaceAlmostClosedNotice();

    const unsubscribe = this.$store.subscribe(({ type }: MutationPayload) => {
      if (REMOVE_ORDER_MUTATION_TYPE === type) {
        document.body.scrollTop = 0;
      }
    });

    this.$on('hook:beforeDestroy', unsubscribe);
  }

  private selectPlaceByPlaceIdFromRouter() {
    if (null === this.selectedPlace) {
      try {
        this.tryToSelectPlaceByPlaceId(this.$route.params.placeId);
      } catch (e) {
        this.$router.replace({
          name: 'selectPlace',
          params: {
            projectId: this.$route.params.projectId,
          },
        });
      }
    }
  }

  private closeInstallPwaNoticeIfItIsAlreadyInstalled() {
    if (null === this.beforeInstallPromptEvent) {
      this.installPwaNoticeClosed = true;
    }
  }

  private async initWidgetByCurrentEnvironment() {
    if (undefined !== window.VK) {
      return this.initWidgetByVkApi();
    } else if (window.FB) {
      return window.FB.getLoginStatus(({ status }: FacebookGetLoginStatusResponse) => {
        if (FacebookLoginStatuses.CONNECTED === status) {
          this.initWidgetByFacebookApi();
        } else {
          window.FB.login(this.initWidgetByFacebookApi);
        }
      });
    }
  }

  private async initWidgetByVkApi() {
    if (undefined !== window.VK) {
      return window.VK.init(this.fetchOrderClientFromVKApi, process.env.VUE_APP_VK_API_VERSION);
    }
  }

  private async fetchOrderClientFromVKApi() {
    return window.VK.api(
      'users.get',
      {
        'fields': 'bdate, career, city, connections, contacts, counters, country, domain, education, followers_count, has_mobile, has_photo, home_town, interests, last_seen, movies, music, occupation, online, personal, photo_max, photo_max_orig, relatives, site, trending, verified, photo_50, photo_200',
      },
      this.setOrderClientByVKUserResponse,
    );
  }

  private setOrderClientByVKUserResponse({ response: [ user ] }: { response: VKUser[] }) {
    this.setOrderClient(
      Client.createByVkUser(user),
    );
  }

  private async initWidgetByFacebookApi() {
    const client = new Client();

    window.FB.api(
      '/me',
      (response) => {
        if (response && !response.error) {
          client.name = response.name;
        }
      },
    );

    window.FB.api(
      '/me/picture',
      (response) => {
        if (response && !response.error) {
          client.name = response.data.url;
        }
      },
    );

    //Todo: Дождаться ответ от сервера, а не мутировать стор
    this.setOrderClient(client);
  }

  private closeInstallPwaNotice() {
    this.installPwaNoticeClosed = true;
  }

  private openPlaceAlreadyClosedNoticeIfItNeeded(): void {
    if (!this.isPlaceOpen) {
      this.placeAlreadyClosedNoticeClosed = false;
    }
  }

  private openPlaceAlreadyClosedNotice(): void {
    this.placeAlreadyClosedNoticeClosed = false;
  }

  private closePlaceAlreadyClosedNotice(): void {
    this.placeAlreadyClosedNoticeClosed = true;
  }

  private openPlaceAlmostClosedNotice(): void {
    if (this.isPlaceOpen) {
      const timeToClose = this.selectedPlace.getTimeToClose(this.nowInSelectedPlaceInSeconds);
      const isWorkingTillEndOfDay = this.selectedPlace.isWorkingTillEndOfDay(this.nowInSelectedPlaceInSeconds);

      if (
        timeToClose < ONE_MINUTE_IN_SECONDS * 30
        && (
          !isWorkingTillEndOfDay
          || (isWorkingTillEndOfDay && !this.selectedPlace.isNextDayWorkingFromStartOfDay)
        )
      ) {
        this.placeAlmostClosedNoticeClosed = false;
      }
    }
  }

  private closePlaceAlmostClosedNotice(): void {
    this.placeAlmostClosedNoticeClosed = true;
  }

  private restoreOrder() {
    const rawOrder = window.localStorage.getItem(`${this.projectId}.order`);

    if (rawOrder === null) {
      return new TakeAway();
    }

    const restoredOrder = JSON.parse(rawOrder);
    const now = new Date();

    if (now.getTime() > restoredOrder.expiry) {
      window.localStorage.removeItem(`${this.projectId}.order`);

      return new TakeAway();
    }

    const order = new TakeAway();

    for (const restoredProduct of restoredOrder.products) {
      const product = this.getProductByRestoredProduct(restoredProduct);

      if (!product) {
        continue;
      }

      const orderProduct = OrderProduct.createFromRestoredProduct(restoredProduct, product);

      if (orderProduct) {
        order.addOrderProduct(orderProduct);
      }
    }

    for (const promotionId in restoredOrder.buyXGetY) {
      const restoredProduct = restoredOrder.buyXGetY[promotionId];
      const product = this.getProductByRestoredProduct(restoredProduct);

      if (!product) {
        continue;
      }

      const orderProduct = OrderProduct.createFromRestoredProduct(restoredProduct, product);

      if (orderProduct) {
        order.addPromotionProduct(promotionId, orderProduct);
      }
    }

    return order;
  }

  @Mutation('setOrderClient', {
    namespace: 'takeAway/order',
  })
  private setOrderClient!: MutationMethod;

  @Mutation('setOrder', {
    namespace: 'takeAway/order',
  })
  private setOrder!: MutationMethod;

  @Action('fetchMenuByPlaceId', {
    namespace: 'takeAway/menu',
  })
  private fetchMenuByPlaceId!: ActionMethod;

  @Action('fetchPromotions', {
    namespace: 'promotion',
  })
  private fetchPromotions!: ActionMethod;

  @Action('tryToSelectPlaceByPlaceId', {
    namespace: 'place',
  })
  private tryToSelectPlaceByPlaceId!: ActionMethod;
}
