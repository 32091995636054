





















import PlaceLogo from '@/components/ui/place/PlaceLogo/index.vue';
import { Component, Vue } from 'vue-property-decorator';
import {State} from 'vuex-class';
import Place from '@/models/place';

@Component({
  name: 'TheHeader',
  components: {
    PlaceLogo,
  },
})
export default class TheHeader extends Vue {
  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;
}
