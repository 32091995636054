import { ActionContext, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import Place from '@/models/place';
import UndefinedPlaceIdInRoute from '@/exceptions/undefinedPlaceIdInRoute';

interface PlaceState {
  places: Place[],
  selectedPlace: Place | null
}

const namespaced: boolean = true;

const state: PlaceState = {
  places: [],
  selectedPlace: null
};

const getters: GetterTree<PlaceState, RootState> = {
  takeAwayOrDeliveryEnabled(state: PlaceState) {
    if (state.selectedPlace === null) {
      return false;
    }

    return state.selectedPlace.settings.widget.takeAwayEnabled || state.selectedPlace.settings.widget.deliveryEnabled;
  }
};

const actions = {
  tryToSelectPlaceByPlaceId({ state, commit, rootState }: ActionContext<PlaceState, RootState>, placeId: string) {
    const selectedPlace = state.places.find(({ id }: Place) => id === placeId);

    if (undefined !== selectedPlace) {
      commit('selectPlace', {
        place: selectedPlace,
        projectId: rootState.projectId,
      });
    } else {
      throw new UndefinedPlaceIdInRoute();
    }
  },
  setPlaces({ commit, rootState }: ActionContext<PlaceState, RootState>, places: Place[]) {
    commit('setPlaces', places);

    let selectedPlace: Place | null = places[0] || null;
    const placeId = window.localStorage.getItem(String(rootState.projectId));

    if (placeId) {
      selectedPlace = places.find(({ id }: Place): boolean => id === placeId) || places[0] || null;
    }

    commit('selectPlace', {
      place: selectedPlace,
      projectId: rootState.projectId,
    });
  }
};

const mutations: MutationTree<PlaceState> = {
  setPlaces(state, places: Place[]) {
    state.places = places;
  },
  selectPlace(state, { place, projectId }: { place: Place, projectId: string }) {
    state.selectedPlace = place;

    if (place) {
      window.localStorage.setItem(projectId, place.id);
    }
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
