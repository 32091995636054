





















































































































import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import OrderProduct from '@/models/orderProduct';
import Place from '@/models/place';
import Product from '@/models/product';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {State, Mutation, Getter} from 'vuex-class';
import {MutationMethod} from 'vuex';
import BuyXGetY from '@/models/buyXGetY';

@Component({
  name: 'PromotionProductAddPopup',
  components: {
    DefaultImage,
    PopupWrapper,
  },
})
export default class PromotionProductAddPopup extends Vue {
  @Prop({
    type: BuyXGetY,
    required: true,
  })
  private promotion!: BuyXGetY;

  @Prop({
    type: Product,
    required: true,
  })
  private product!: Product;

  @Prop({
    type: Number,
    required: true,
  })
  private maxCount!: number;

  private orderProduct: OrderProduct = OrderProduct.createOrderProductWithZeroCount({
    product: this.product,
  });

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @Getter('getProductByProjectProductId', {
    namespace: 'takeAway/menu',
  })
  private getProductByProjectProductId!: (projectProductId: string) => Product | null;

  @Getter('takeAwayOrDeliveryEnabled', {
    namespace: 'place',
  })
  private takeAwayOrDeliveryEnabled!: boolean;

  private addOrderProductToOrder() {
    this.addPromotionProduct({
      promotion: this.promotion,
      orderProduct: this.orderProduct,
    });
    this.close();
  }

  private incrementOrderProductCount() {
    if (this.orderProduct.count < this.maxCount) {
      this.orderProduct.increment();
    }
  }

  private decrementOrderProductCount() {
    this.orderProduct.decrement();
  }

  private close() {
    this.$emit('close');

    this.$destroy();
  }

  @Mutation('addPromotionProduct', {
    namespace: 'takeAway/order',
  })
  addPromotionProduct!: MutationMethod;
}
