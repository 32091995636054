

























































import CartPopup from '@/components/ui/popups/takeAway/CartPopup/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import Place from '@/models/place';
import TakeAway from '@/models/takeAway';
import PlaceAlreadyClosedNotice from './PlaceAlreadyClosedNotice.vue';
import TakeAwayMenuCartPanelProduct
  from '@/pages/TakeAwayMenu/components/TakeAwayMenuCartPanelProduct.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import TakeAwayMenuCartPanelPromotionProduct
  from '@/pages/TakeAwayMenu/components/TakeAwayMenuCartPanelPromotionProduct.vue';

@Component({
  name: 'TakeAwayMenuCartPanel',
  components: {
    PlaceAlreadyClosedNotice,
    TakeAwayMenuCartPanelPromotionProduct,
    TakeAwayMenuCartPanelProduct
  }
})
export default class TakeAwayMenuCartPanel extends Vue {
  @Prop({
    type: TakeAway,
    required: true
  })
  private order!: TakeAway;

  private popupController: PopupControllerPublicProperties = getPopupController();
  private placeAlreadyClosedNoticeClosed: boolean = true;

  private get isPlaceOpen(): boolean {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  private mounted() {
    document.body.appendChild(<HTMLElement> this.$el);
  }

  private beforeDestroy() {
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }

  private openCartPopup() {
    if (this.isPlaceOpen) {
      this.popupController.createPopup(CartPopup);
    } else {
      this.openPlaceAlreadyClosedNotice();
    }
  }

  private openPlaceAlreadyClosedNotice(): void {
    this.placeAlreadyClosedNoticeClosed = false;
  }

  private closePlaceAlreadyClosedNotice(): void {
    this.placeAlreadyClosedNoticeClosed = true;
  }
}
