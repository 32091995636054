






















































































































import { Component, Vue } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { State, Getter, Mutation } from 'vuex-class';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import getPopupController from '@/lib/vuePopup';
import LoginPopup from '@/components/ui/popups/user/LoginPopup/index.vue';

@Component({
  name: 'TheNavbar',
})
export default class TheNavbar extends Vue {
  private popupController: PopupControllerPublicProperties = getPopupController();

  @Getter('activeOrders', {
    namespace: 'orderHistory'
  })
  activeOrders!: any[];

  @State('projectId')
  projectId!: string;

  @State('user', {
    namespace: 'user'
  })
  user!: any | null;

  private get orderHistoryLink() {
    return {
      name: 'orderHistory',
      params: {
        projectId: this.$route.params.projectId,
      },
    };
  }

  private get chatListLink() {
    return {
      name: 'chatList',
      params: {
        projectId: this.$route.params.projectId,
      },
    };
  }

  private openLoginPopup() {
    this.popupController.createPopup(LoginPopup);
  }

  private logout() {
    this.popupController.createPopup(LoginPopup);

    this.$router.push({
      name: 'selectPlace',
      params: {
        projectId: this.$route.params.projectId,
      },
    });

    this.resetUserState();
    this.resetOrderHistoryState();
    this.resetChatState();

    window.localStorage.removeItem(`${this.projectId}.accessToken`);
  }

  @Mutation('resetState', {
    namespace: 'user',
  })
  private resetUserState!: MutationMethod;

  @Mutation('resetState', {
    namespace: 'orderHistory',
  })
  private resetOrderHistoryState!: MutationMethod;

  @Mutation('resetState', {
    namespace: 'chat',
  })
  private resetChatState!: MutationMethod;

}
