








































import MenuCategory from '@/models/menuCategory';
import animation from '@/utils/animation';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'TakeAwayMenuCategoryFilter',
})
export default class TakeAwayMenuCategoryFilter extends Vue {
  private selectedCategoryId: string = '';
  private scrollHandlingEnabled: boolean = true;
  private previousScroll: number = 0;

  @State('menu', {
    namespace: 'takeAway/menu',
  })
  private menu!: MenuCategory[];

  private created() {
    if (this.menu.length > 0) {
      this.selectedCategoryId = this.menu[0].id;
    }

    document.addEventListener('scroll', this.handleActiveCategoryVisibility, {
      capture: true,
      passive: true,
    });
  }

  private async selectCategoryId(selectedCategoryId: string) {
    this.deactivateAllCategories();

    this.selectedCategoryId = selectedCategoryId;

    const category = document.getElementById(`category-${selectedCategoryId}`);

    if (category !== null) {
      this.scrollHandlingEnabled = false;

      this.activateCategory(category);

      await animation.scrollIntoViewVertical(
        category,
        {
          scrollWrapper: document.body,
          offset: {
            top: 110,
          },
        },
      )
        .finally(() => {
          this.scrollHandlingEnabled = true;
        });
    }
  }

  private handleActiveCategoryVisibility() {
    if (this.scrollHandlingEnabled) {
      const scrollTop = document.body.scrollTop;

      if (scrollTop === 0 && this.previousScroll !== 0) {
        this.previousScroll = 0;

        const firstCategory = document.querySelector<HTMLElement>('.jrm-embedmenu-category');

        this.deactivateAllCategories();
        this.activateCategory(firstCategory);

        return;
      }

      this.previousScroll = scrollTop;

      const topPadding = 110;
      const activeCategory = document.querySelector<HTMLElement>('.jrm-embedmenu-category.jrm-active');

      if (null === activeCategory) {
        const firstCategory = document.querySelector<HTMLElement>('.jrm-embedmenu-category');

        this.activateCategory(firstCategory);

        return;
      }

      const { top, bottom } = activeCategory.getBoundingClientRect();

      if (bottom < topPadding && null !== activeCategory.nextElementSibling) {
        this.deactivateAllCategories();
        this.activateCategory(<HTMLElement>activeCategory.nextElementSibling);

        return;
      }

      if (top > window.outerHeight && null !== activeCategory.previousElementSibling) {
        this.deactivateAllCategories();
        this.activateCategory(<HTMLElement>activeCategory.previousElementSibling);
      }
    }
  }

  private activateCategory(category: HTMLElement | null) {
    if (null === category) {
      return;
    }

    category.classList.add('jrm-active');

    const categoryId = category.dataset.categoryId;
    const miniCategory = document.getElementById(`mini-category-${categoryId}`);

    if (null === miniCategory) {
      return;
    }

    animation.scrollIntoView(miniCategory, {
      offset: {
        left: 10,
      },
      duration: 100,
    });
    miniCategory.classList.add('jrm-primary');
  }

  private deactivateAllCategories() {
    const activeMiniCategories = document.querySelectorAll<HTMLElement>('.jrm-mini-category.jrm-primary');

    for (const activeMiniCategory of activeMiniCategories) {
      activeMiniCategory.classList.remove('jrm-primary');
    }

    const activeCategories = document.querySelectorAll<HTMLElement>('.jrm-embedmenu-category.jrm-active');

    for (const activeCategory of activeCategories) {
      activeCategory.classList.remove('jrm-active');
    }
  }
}
