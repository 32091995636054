


































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BuyXGetY from '@/models/buyXGetY';
import PromotionViewPopup from '@/components/ui/popups/promotions/PromotionViewPopup/index.vue';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import getPopupController from '@/lib/vuePopup';

@Component({
  name: 'PromotionCard',
})
export default class PromotionCard extends Vue {
  @Prop({
    type: BuyXGetY,
    required: true
  })
  promotion!: BuyXGetY;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private openPromotionViewPopup() {
    this.popupController.createPopup(PromotionViewPopup, {
      propsData: {
        promotion: this.promotion,
      }
    });
  }
}
