import eventBus from '@/lib/eventBus';
import store from '@/store';
import router from '@/router';
import i18n from '@/plugins/vue-i18n';
import {
  PopupControllerPublicProperties,
  PopupListener,
  PopupRendererInterface,
  PopupStorageInterface,
  NewPopupOptions,
  VueEventSubscriberTypes
} from './interfaces';
import getPopupStorage from './popupStorage';
import getPopupRenderer from './popupRenderer';
import Vue, { VueConstructor } from 'vue';

const popups: any[] = [];
const popupStorage: PopupStorageInterface = getPopupStorage();
const popupRenderer: PopupRendererInterface = getPopupRenderer(popupStorage);

eventBus.$on('beforeRouteLeave', ({ next }: any) => {
  store.commit('deactivateBackButton');

  const popupIndex = popups.findIndex((popup: any) => popup.$el === popupStorage.getLastPopupStorageItem().element);

  if (-1 === popupIndex) {
    return next();
  }

  next(false);

  popups[popupIndex].close();
})

export default function PopupController(): PopupControllerPublicProperties {
  return Object.freeze({
    createPopup,
    havePopupStorageItems,
  });

  function createPopup(
    PopupClass: VueConstructor,
    { listeners, ...options }: NewPopupOptions = { listeners: [] }
  ) {
    const popup: Vue = new PopupClass({
      i18n,
      store,
      router,
      created() {
        if (listeners) {
          subscribeToEvents(this, listeners);
        }
      },
      mounted() {
        if (this.$el && this.$el instanceof HTMLElement) {
          popupRenderer.renderPopupByPopupHTMLElement(<HTMLElement>this.$el);
        }
      },
      beforeDestroy() {
        if (this.$el) {
          popupRenderer.removePopupByPopupHTMLElement(<HTMLElement> this.$el);
        }

        const index = popups.indexOf(this);

        if (-1 !== index) {
          popups.splice(index, 1);
        }
      },
      ...options
    });

    popup.$mount();

    popups.push(popup);

    return popup;
  }

  function havePopupStorageItems() {
    return popupStorage.havePopupStorageItems();
  }

  function subscribeToEvents(popup: Vue, listeners: Array<PopupListener>) {
    listeners.forEach(({ event, callback, type }: PopupListener) => {
      switch (type) {
        case VueEventSubscriberTypes.ONCE: {
          if (typeof event === 'string') {
            popup.$once(event, callback);
          }

          break;
        }

        case VueEventSubscriberTypes.ON:
        default: {
          popup.$on(event, callback);
        }
      }
    });
  }
}
