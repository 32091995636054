





































import PlaceAppLogo from '@/components/ui/place/PlaceAppLogo/index.vue';
import Place from '@/models/place';
import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { MutationMethod } from 'vuex';

@Component({
  name: 'InstallPwaNotice',
  components: {
    PlaceAppLogo
  },
})
export default class InstallPwaNotice extends Vue {
  @State('beforeInstallPromptEvent')
  beforeInstallPromptEvent!: Event | any;

  @State('selectedPlace', {
    namespace: 'place',
  })
  selectedPlace!: Place;

  private install() {
    this.beforeInstallPromptEvent.prompt();
    this.beforeInstallPromptEvent.userChoice
      .then((choiceResult: any) => {
        if ('accepted' === choiceResult.outcome) {
          this.installApp();
          this.close();
        }
      });
  }

  private close() {
    this.$emit('close');
  }

  @Mutation('installApp')
  private installApp!: MutationMethod;
}
