





































































































































import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import Ingredient from '@/models/ingredient';
import IngredientsGroup from '@/models/ingredientGroup';
import OrderProduct from '@/models/orderProduct';
import Place from '@/models/place';
import Product from '@/models/product';
import TakeAway from '@/models/takeAway';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, State, Mutation } from 'vuex-class';
import OneOfGroup from './components/OneOfGroup.vue';
import OptionalGroup from './components/OptionalGroup.vue';
import BuyXGetY from '@/models/buyXGetY';
import {MutationMethod} from 'vuex';

@Component({
  name: 'PromotionProductWithIngredientsAddPopup',
  components: {
    DefaultImage,
    OneOfGroup,
    OptionalGroup,
    PopupWrapper,
  },
})
export default class PromotionProductWithIngredientsAddPopup extends Vue {
  @Prop({
    type: BuyXGetY,
    required: true,
  })
  private promotion!: BuyXGetY;

  @Prop({
    type: Product,
    required: true,
  })
  private product!: Product;

  @Prop({
    type: Number,
    required: true,
  })
  private maxCount!: number;

  private orderProduct: OrderProduct = OrderProduct.createOrderProductWithZeroCount({
    product: this.product,
  });

  private get canIncrementIngredientCount(): boolean {
    return this.selectedPlace.settings.widget.maxIngredientCount > this.orderProduct.ingredientsCount;
  }

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  @Getter('takeAwayOrDeliveryEnabled', {
    namespace: 'place',
  })
  private takeAwayOrDeliveryEnabled!: boolean;

  private incrementOrderProductIngredientCountByIngredient(ingredient: Ingredient) {
    if (this.canIncrementIngredientCount) {
      this.orderProduct.incrementOrderProductIngredientCountByIngredient(ingredient);
    }
  }

  private decrementOrderProductIngredientCountByIngredient(ingredient: Ingredient) {
    this.orderProduct.decrementOrderProductIngredientCountByIngredient(ingredient);
  }

  private selectOneOfIngredient(
    payload: { selectedIngredient: Ingredient, oldSelectedIngredient: Ingredient, group: IngredientsGroup },
  ) {
    this.orderProduct.selectOneOfIngredient(payload);
  }

  private addOrderProductToOrder() {
    this.addPromotionProduct({
      promotion: this.promotion,
      orderProduct: this.orderProduct,
    });
    this.close();
  }

  private incrementOrderProductCount() {
    if (this.orderProduct.count < this.maxCount) {
      this.orderProduct.increment();
    }
  }

  private decrementOrderProductCount() {
    this.orderProduct.decrement();
  }

  private close() {
    this.$emit('close');

    this.$destroy();
  }

  @Mutation('addPromotionProduct', {
    namespace: 'takeAway/order',
  })
  addPromotionProduct!: MutationMethod;
}
