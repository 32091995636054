import chatService from '@/api/services/chat.service';
import { GetterTree, MutationTree, ActionContext } from 'vuex';
import { RootState } from '@/store';

interface ChatState {
  chats: any[];
  isFetchingChats: boolean
}

const namespaced: boolean = true;

const getDefaultState = (): ChatState => ({
  chats: [],
  isFetchingChats: false
});

const state: ChatState = getDefaultState();

const getters: GetterTree<ChatState, RootState> = {};

const actions = {
  async fetchChats({ commit }: ActionContext<ChatState, RootState>) {
    commit('setIsFetchingChat', true);

    try {
      const chats = await chatService.fetchChats();

      commit('setChats', chats);
    } catch (e) {
    }

    commit('setIsFetchingChat', false);
  }
};

const mutations: MutationTree<ChatState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setChats(state, chats) {
    state.chats = chats;
  },
  setIsFetchingChat(state, isFetchingChats: boolean) {
    state.isFetchingChats = isFetchingChats;
  },
  createMessage(state, messageProps) {

  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
