




































































import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import Ingredient from '@/models/ingredient';
import OrderProduct from '@/models/orderProduct';
import OrderProductIngredient from '@/models/orderProductIngredient';
import Place from '@/models/place';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import PromotionOrderProductWithIngredientsEditPopup
  from '@/components/ui/popups/takeAway/PromotionOrderProductWithIngredientsEditPopup/index.vue';

@Component({
  name: 'TakeAwayMenuCartPanelPromotionProduct',
  components: { DefaultImage },
})
export default class TakeAwayMenuCartPanelPromotionProduct extends Vue {
  @Prop({
    type: OrderProduct,
    required: true,
  })
  product!: OrderProduct;

  @Prop({
    type: String,
    required: true,
  })
  promotionId!: string;

  private popupController: PopupControllerPublicProperties = getPopupController();

  @State('selectedPlace', {
    namespace: 'place',
  })
  selectedPlace!: Place;

  private get firstThreeIngredients(): Ingredient[] {
    return this.flattenIngredients.slice(0, 3);
  }

  private get flattenIngredients(): Ingredient[] {
    return this.product.ingredients.reduce(
      (
        ingredients: Ingredient[],
        orderProductIngredient: OrderProductIngredient,
      ): Ingredient[] => {
        for (let count = 0; count < orderProductIngredient.count; count++) {
          ingredients.push(orderProductIngredient.ingredient);
        }

        return ingredients;
      },
      [],
    );
  }

  private get countOfIngredientsOverThree(): number {
    return this.flattenIngredients.length - 3;
  }

  private openOrderProductViewPopup() {
    this.popupController.createPopup(PromotionOrderProductWithIngredientsEditPopup, {
      propsData: {
        initOrderProduct: this.product,
        promotionId: this.promotionId,
      },
    });
  }
}
