export interface FB {
  api(path: string, callback: (response: any) => void): void;

  getLoginStatus(callback: (response: FacebookGetLoginStatusResponse) => void): void;

  login(callback: Function): void;
}

export type FacebookGetLoginStatusResponse = {
  status: FacebookLoginStatuses
}

export enum FacebookLoginStatuses {
  CONNECTED = 'connected'
}
