




























































import PageWrapper from '@/components/layout/PageWrapper/index.vue';
import TheHeader from '@/components/layout/TheHeader/index.vue';
import TheNavbar from '@/components/layout/TheNavbar/index.vue';
import TheFooter from '@/components/layout/TheFooter/index.vue';
import PlaceLogo from '@/components/ui/place/PlaceLogo/index.vue';
import PlaceCommentPopup from '@/components/ui/popups/place/PlaceCommentPopup/index.vue';
import getPopupController from '@/lib/vuePopup';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import PlaceList from '@/pages/SelectPlacePage/components/PlaceList.vue';
import PlacesMap from '@/pages/SelectPlacePage/components/PlacesMap.vue';
import { Component, Vue } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { State, Mutation } from 'vuex-class';
import Place from '@/models/place';

const TABS = Object.freeze({
  LIST: 'LIST',
  MAP: 'MAP',
});

@Component({
  components: {
    PlaceLogo,
    PageWrapper,
    TheHeader,
    TheNavbar,
    PlacesMap,
    PlaceList,
    TheFooter,
  },
})
export default class SelectPlacePage extends Vue {
  private TABS = TABS;
  private selectedTab = TABS.LIST;
  private popupController: PopupControllerPublicProperties = getPopupController();

  private get defaultPlaceId(): string | null {
    for (const place of this.places) {
      if (place.id) {
        return place.id;
      }
    }

    return null;
  }

  @State('places', {
    namespace: 'place',
  })
  private places!: Place[];

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('projectId')
  projectId!: string;

  private get isExactRoute() {
    return this.$route.name === 'selectPlace';
  }

  private async created() {
    if (this.places.length === 1 && this.isExactRoute) {
      this.redirectToMenu(this.places[0]);
    }
  }

  private selectTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  private redirectToMenu(place: Place) {
    if (place.settings.widget.additionalInformationEnabled) {
      this.popupController.createPopup(PlaceCommentPopup, {
        propsData: {
          comment: place.settings.widget.additionalInformation,
        },
      });
    }

    this.$router.replace({
      name: 'takeAwayMenu',
      params: {
        projectId: this.$route.params.projectId,
        placeId: place.id,
      },
    });
  }

  private onPlaceSelected(place: Place) {
    if (place.settings.widget.additionalInformationEnabled) {
      this.popupController.createPopup(PlaceCommentPopup, {
        propsData: {
          comment: place.settings.widget.additionalInformation,
        },
      });
    }
  }
}
