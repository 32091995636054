




























































































































































import Place from '@/models/place';
import stringFormatter from '@/utils/format/string';
import SystemDate from '@/utils/systemDate';
import isEqual from 'lodash.isequal';
import { Component, Vue } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';
import { State, Getter, Mutation } from 'vuex-class';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import getPopupController from '@/lib/vuePopup';
import LoginPopup from '@/components/ui/popups/user/LoginPopup/index.vue';

const WEEKEND_SCHEDULE: [ number, number ] = [ 0, 0 ];
const ALL_DAY_WORK_SCHEDULE: [ number, number ] = [ 0, 86400 ];

@Component({
  name: 'ThePlaceNavbar',
})
export default class ThePlaceNavbar extends Vue {
  private popupController: PopupControllerPublicProperties = getPopupController();

  private get time() {
    const dayOfWeek = this.today.getEuropeanDay();
    const timetable = this.selectedPlace.getTimetableByDayOfWeek(dayOfWeek).workingHours;

    if (isEqual(timetable, WEEKEND_SCHEDULE)) {
      return 'Выходной';
    } else if (isEqual(timetable, ALL_DAY_WORK_SCHEDULE)) {
      return 'Круглосуточно';
    }

    const formattedOpenTime = this.getHoursAndMinutesFromDayTimestamp(timetable[0]);
    const formattedCloseTime = this.getHoursAndMinutesFromDayTimestamp(timetable[1]);

    return `${formattedOpenTime} - ${formattedCloseTime}`;
  }

  private getHoursAndMinutesFromDayTimestamp(timestamp: number): string {
    const hours = Math.floor(timestamp / 3600);
    const minutes = timestamp % 3600 / 60;

    return `${stringFormatter.t2(hours)}:${stringFormatter.t2(minutes)}`;
  }

  @State('today', {
    namespace: 'timer',
  })
  private today!: SystemDate;

  @Getter('activeOrders', {
    namespace: 'orderHistory'
  })
  activeOrders!: any[];

  @State('projectId')
  projectId!: string;

  @State('user', {
    namespace: 'user'
  })
  user!: any | null;

  @State('selectedPlace', {
    namespace: 'place'
  })
  selectedPlace!: Place;

  private get menuLink() {
    return {
      name: 'takeAwayMenu',
      params: {
        projectId: this.$route.params.projectId,
        placeId: this.selectedPlace.id,
      }
    };
  }

  private get promotionsLink() {
    return {
      name: 'promotions',
      params: {
        projectId: this.$route.params.projectId,
        placeId: this.selectedPlace.id,
      }
    };
  }

  private get placeViewLink() {
    return {
      name: 'placeView',
      params: {
        projectId: this.$route.params.projectId,
        placeId: this.selectedPlace.id,
      }
    };
  }

  private get orderHistoryLink() {
    return {
      name: 'orderHistory',
      params: {
        projectId: this.$route.params.projectId,
      },
    };
  }

  private get chatListLink() {
    return {
      name: 'chatList',
      params: {
        projectId: this.$route.params.projectId,
      },
    };
  }

  private openLoginPopup() {
    this.popupController.createPopup(LoginPopup);
  }

  private logout() {
    this.popupController.createPopup(LoginPopup);

    this.$router.push({
      name: 'selectPlace',
      params: {
        projectId: this.$route.params.projectId,
      },
    });

    this.resetUserState();
    this.resetOrderHistoryState();
    this.resetChatState();

    window.localStorage.removeItem(`${this.projectId}.accessToken`);
  }

  @Mutation('resetState', {
    namespace: 'user',
  })
  private resetUserState!: MutationMethod;

  @Mutation('resetState', {
    namespace: 'orderHistory',
  })
  private resetOrderHistoryState!: MutationMethod;

  @Mutation('resetState', {
    namespace: 'chat',
  })
  private resetChatState!: MutationMethod;

}
