type Options = {
  scrollWrapper?:  HTMLElement,
  offset?: {
    top?: number,
    left?: number,
  },
  duration?: number,
}

export default {
  scrollIntoView(element: HTMLElement, options: Options) {
    const scrollWrapper: HTMLElement = <HTMLElement> options.scrollWrapper || element.parentNode;

    if (null === scrollWrapper) {
      throw Error('Scroll wrapper is null');
    }

    const scrollWrapperRect = scrollWrapper.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    const leftOffset = options.offset && options.offset.left || 0;
    const width = scrollWrapper.scrollLeft - scrollWrapperRect.right + elementRect.right + leftOffset;

    return this.animate((timePassed: number, stepsToEnd: number) => {
      if (stepsToEnd !== 0) {
        const pixelsToEnd = scrollWrapper.scrollLeft - width;

        scrollWrapper.scrollLeft -= pixelsToEnd / stepsToEnd;
      } else {
        scrollWrapper.scrollLeft = width;
      }
    }, options.duration || 500)
  },
  scrollIntoViewVertical(element: HTMLElement, options: Options) {
    const scrollWrapper: HTMLElement = <HTMLElement> options.scrollWrapper || element.parentNode;

    if (null === scrollWrapper) {
      throw Error('Scroll wrapper is null');
    }

    const scrollWrapperRect = scrollWrapper.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    const topOffset = options.offset && options.offset.top || 0;
    const height = scrollWrapper.scrollTop - scrollWrapperRect.top + elementRect.top - topOffset;

    return this.animate((timePassed: number, stepsToEnd: number) => {
      if (stepsToEnd !== 0) {
        const pixelsToEnd = scrollWrapper.scrollTop - height;

        scrollWrapper.scrollTop -= pixelsToEnd / stepsToEnd;
      } else {
        scrollWrapper.scrollTop = height;
      }
    }, options.duration || 500)
  },
  async animate(draw: Function, duration: number) {
    return new Promise((resolve) => {
      const start = performance.now();

      let stepTime = 20;

      window.requestAnimationFrame(function animate(time) {
        let timePassed = time - start;

        if (timePassed > duration) {
          timePassed = duration;
        } else if (timePassed < 0) {
          timePassed = 0;
        }

        const stepsToEnd = Math.floor((duration - timePassed) / stepTime);

        draw(timePassed, stepsToEnd);

        if (timePassed < duration) {
          window.requestAnimationFrame(animate);
        } else {
          resolve(duration);
        }
      });
    })
  }
}
