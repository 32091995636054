import eventBus from '@/lib/eventBus';
import getPopupController from '@/lib/vuePopup';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import camelCase from 'lodash.camelcase';
import router from '../router';
import store from '../store';
import { Route, RawLocation } from 'vue-router/types/router';
import Vue from 'vue';
import ReservedMiddlewareModuleName from '@/exceptions/reservedMiddlewareModuleName';
import UndefinedMiddlewareModuleName from '@/exceptions/undefinedMiddlewareModuleName';

const popupController: PopupControllerPublicProperties = getPopupController();

export default async (to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void) => {
  if (popupController.havePopupStorageItems() && store.state.backActivated) {
    eventBus.$emit('beforeRouteLeave', { from, to, next });
    return;
  }

  if (!popupController.havePopupStorageItems() && store.state.backActivated) {
    store.commit('deactivateBackButton');
  }

  const components: any = router.getMatchedComponents(to);
  const toComponent = components[components.length - 1];

  let redirectRoute;

  if (toComponent) {
    const componentOptions = await getComponentOptionsByVueConstructorOrAsyncComponent(toComponent);

    if (componentOptions.middleware) {
      const middleware = getModule(componentOptions.middleware);

      redirectRoute = await middleware({
        store,
        to,
        from
      });
    }
  }

  next(redirectRoute);
};

function getModule(moduleName: string) {
  checkMiddlewareModuleNameForReservedName(moduleName);

  const requireModule = require.context('./', false, /\.ts$/);
  const fileName = requireModule
    .keys()
    .find(
      getMiddlewareModuleFileFinderByModuleName(moduleName)
    );

  if (fileName !== undefined) {
    const requiredFile = requireModule(fileName);

    return requiredFile.default || requiredFile;
  }

  throw new UndefinedMiddlewareModuleName(moduleName);
}

function checkMiddlewareModuleNameForReservedName(moduleName: string) {
  const exceptionModuleName = __filename.replace(/(\/|\.js)/g, '');

  if (exceptionModuleName === moduleName) {
    throw new ReservedMiddlewareModuleName();
  }
}

function getMiddlewareModuleFileFinderByModuleName(moduleName: string) {
  return (fileName: any) => {
    fileName = camelCase(
      fileName.replace(/(\.\/|\.ts)/g, '')
    );

    return fileName === moduleName;
  };
}

async function getComponentOptionsByVueConstructorOrAsyncComponent(component: any) {
  if (component.prototype instanceof Vue) {
    return component.options;
  }

  let resolvedComponent = await component();

  if (undefined !== resolvedComponent.component) {
    resolvedComponent = await resolvedComponent.component;
  }

  return resolvedComponent.default.extendOptions;
}
