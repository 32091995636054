import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import menuApi from '@/api/services/menu.service';
import MenuCategory from '@/models/menuCategory';
import Product from '@/models/product';

const namespaced: boolean = true;

type MenuState = {
  menu: MenuCategory[];
  isFetchingMenu: boolean;
};

const state: MenuState = {
  menu: [],
  isFetchingMenu: false,
};

const getters: GetterTree<MenuState, RootState> = {
  getProductByProjectProductId(state) {
    return (projectProductId: string): Product | null => {
      for (const category of state.menu) {
        const product = category.products.find(
          ({ projectProduct }: Product): boolean => projectProduct.id == projectProductId
        );

        if (product) {
          return product
        }
      }

      return null;
    }
  },
  getProductByRestoredProduct(state) {
    return (restoredProduct: any): Product | null => {
      const category = state.menu.find(({ id }: MenuCategory) => id === restoredProduct.categoryId);

      if (category === undefined) {
        return null;
      }

      const product = category.products.find(
        ({ id }: Product): boolean => id == restoredProduct.id
      )

      return product || null;
    }
  }
};

const actions: ActionTree<MenuState, RootState> = {
  async fetchMenuByPlaceId({ commit, state }: ActionContext<MenuState, RootState>, placeId: string) {
    if (!state.isFetchingMenu) {
      commit('setIsFetchingMenu', true);

      return menuApi.fetchMenuByPlaceId(placeId)
        .then(async (menu: MenuCategory[]) => {
          commit('setMenu', menu);
          commit('setIsFetchingMenu', false);
        });
    }
  },
};

const mutations: MutationTree<MenuState> = {
  setMenu(state, menu: MenuCategory[]) {
    state.menu = menu;
  },
  setIsFetchingMenu(state, isFetchingMenu: boolean) {
    state.isFetchingMenu = isFetchingMenu;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
