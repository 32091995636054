import userService from '@/api/services/user.service';
import hiddenCreditCardFactory from '@/factories/hiddenCreditCard.factory';
import { MiddlewareContext } from '@/interfaces/middleware';

export default async (context: MiddlewareContext) => {
  if (context.store.state.user.user === null) {
    return authUser(context);
  }
};

async function authUser(context: MiddlewareContext) {
  const projectId = window.localStorage.getItem('projectId');

  if (!window.localStorage.getItem(`${projectId}.accessToken`)) {
    return {
      name: 'selectPlace',
      replace: true,
      params: {
        projectId: context.to.params.projectId,
      }
    };
  }

  try {
    context.store.commit('setIsResolvingMiddleware', true);

    // Fetch user throw user store action and add logic for parallel requests to  prevent double fetch
    const { client, orders, creditCards, failedPaymentOrder } = await userService.tryToAuthUserByCookie();

    if (null !== failedPaymentOrder) {
      // return {
      //   name: 'orderStatus',
      //   params: {
      //     projectId: context.to.params.projectId,
      //     orderId: failedPaymentOrderId,
      //   }
      // };
    }

    const cards = creditCards.map(hiddenCreditCardFactory.createFromRawCreditCard);

    context.store.commit('user/setUser', client);
    context.store.commit('user/setCreditCards', cards);
    context.store.commit('orderHistory/setOrders', orders);
    context.store.commit('orderHistory/setIsFetchingOrderHistory', false);
    context.store.commit('setIsResolvingMiddleware', false);
  } catch (error) {
    context.store.commit('setIsResolvingMiddleware', false);

    return {
      name: 'selectPlace',
      replace: true,
      params: {
        projectId: context.to.params.projectId,
      }
    };
  }
}
