










































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'DisabledWidgetOnboarding',
})
export default class DisabledWidgetOnboarding extends Vue {

}
