























































import InstallPwaNotice from './InstallPwaNotice.vue';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'TheFooterWithInstallAppAlert',
  components: {
    InstallPwaNotice
  },
})
export default class TheFooterWithInstallAppAlert extends Vue {
  @State('currentYear', {
    namespace: 'timer'
  })
  currentYear!: number;
}
